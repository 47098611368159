<template>
  <div class="bg-gray-800">
    <div class="flex flex-wrap">
      <div class="w-full">
        <BlockGroup
          :label="'Properties'"
          :closed="false"
        >
          <component
            :is="component"
            :node="node"
            :gui="gui"
          />
        </BlockGroup>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from './NodeSettingMixin'

const GeneralLight = () => import('./lights/GeneralLight')
const SpotLight = () => import('./lights/SpotLight')

export default {
  name: 'LightBlock',

  components: {
    SpotLight, GeneralLight
  },

  mixins: [mixin],

  computed: {
    component () {
      if (this.node.type === 'SpotLight') return 'SpotLight'
      return 'GeneralLight'
    }
  }
}
</script>
